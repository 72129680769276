<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px" >

    <el-form-item label="授权对象" prop="selfAreaCode" label-width="170px">
      <el-select v-model="dataForm.selfAreaCode"  filterable :placeholder="$t('management.pleaseselect')" style="width: 87%" >
        <el-option
            v-for="item in selfAreaCodeList"
            :key="item.value"
            :label="item.name"
            :value="item.id"
            @click.native="pitchOnCodeId(dataForm.selfAreaCode)">
        </el-option>
      </el-select>
    </el-form-item>
      <el-form-item label="授权对象可查看辖区" prop="permissionCode" label-width="170px">
        <el-select v-model="dataForm.permissionCode" multiple filterable :placeholder="$t('management.pleaseselect')" style="width: 87%">
          <el-option
              v-for="item in permissionCode"
              :key="item.value"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
<!--    <el-form-item label="授权人" prop="userId">-->
<!--      <el-input v-model="dataForm.userId" placeholder="授权人" disabled></el-input>-->
<!--    </el-form-item>-->
<!--    <el-form-item label="授权时间" prop="authDate">-->
<!--      <el-input v-model="dataForm.authDate" placeholder="授权时间" disabled></el-input>-->
<!--    </el-form-item>-->
<!--    <el-form-item label="本区域代码" prop="areaCode">
      <el-input v-model="dataForm.areaCode" placeholder="本区域代码" disabled></el-input>
    </el-form-item>-->
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getById , saveOrUpdate } from '@/api/dataflow/olxareaauthmain.js'
import { selectAllCode,pitchOnCode } from '@/api/dataflow/olxareaauthmain.js'
//import {dateFormat} from "../../../../utils";
export default {
    data () {
      return {
        visible: false,
        isOnSubmit: false,
        selfAreaCodeList:[],
        permissionCode:[],
        dataForm: {
          id: null,
          selfAreaCode: '',
          permissionCode:'',
          userId: '',
          authDate: '',
          areaCode: '',
          authorizationCode:'',
          useType:1,
        },
        dataRule: {
          selfAreaCode: [
            { required: true, message: '授权对象不能为空', trigger: 'blur' }
          ],
          permissionCode: [
            { required: true, message: '授权对象可查看辖区不能为空', trigger: 'blur' }
          ],
          userId: [
            { required: true, message: '授权人不能为空', trigger: 'blur' }
          ],
          // authDate: [
          //   { required: true, message: '授权时间不能为空', trigger: 'blur' }
          // ],
          // areaCode: [
          //   { required: true, message: '本区域代码不能为空', trigger: 'blur' }
          // ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id
        this.visible = true
        this.isOnSubmit = false
        //获取登录时的ID
        const userId = this.$store.state.user.regionId !== undefined ? this.$store.state.user.regionId : '0'
        this.getSelfAreaCode(userId)
        this.dataForm.areaCode = this.$store.state.user.regionId !== undefined ? this.$store.state.user.regionId : '0'

        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            getById(id).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.selfAreaCode = data.data.selfAreaCode
                this.dataForm.permissionCode = data.data.permissionCode.split(',')
                this.dataForm.userId = data.data.userId
                this.dataForm.authDate = data.data.authDate
                this.dataForm.areaCode = data.data.areaCode
              }
            }).catch((err) => {
              this.$message.error(err)
            })
          }
        })
      },

      //授权辖区
      getSelfAreaCode(userId) {
        this.dataForm.authorizationCode = userId
        selectAllCode(userId).then(({data}) => {
          this.selfAreaCodeList = data.data
        })
      },

      //可查看辖区  code =点击获取的值
      pitchOnCodeId(code,authorizationCode){
        authorizationCode = this.dataForm.authorizationCode
        pitchOnCode(code,authorizationCode).then(({data}) => {
          this.permissionCode = data.data
        })
      },

      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.isOnSubmit = true
            const codeJson = JSON.stringify(this.dataForm.permissionCode)
            const data_ = {
              'id': this.dataForm.id || undefined,
			        'selfAreaCode': this.dataForm.selfAreaCode,
			        'permissionCode': codeJson,
			        'userId': this.dataForm.userId,
			        'authDate': this.dataForm.authDate,
			        'areaCode': this.dataForm.areaCode,
			        'useType': this.dataForm.useType,
            }
            saveOrUpdate(data_).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 500
                })
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                //this.$message.error("您已经添加过此区域的权限")
                this.$message.error(data.msg)
              }
            }).catch((err) => {
              this.$message.error("添加失败")
            }).finally(()=>{
              this.isOnSubmit = false
            })
          }
        })
      }
    }
  }
</script>
