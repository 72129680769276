<template>
  <div class="box">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="currentChangeHandle(1)" @submit.native.prevent>
      <table class="detailTable_search" style="width: 100%">
        <tr>
<!--          <td class="titleTd width80px">授权人</td>-->
<!--          <td class="valueTd">-->
<!--            <el-input v-model="dataForm.userName" placeholder="请输入授权对象" clearable></el-input>-->
<!--          </td>-->
          <td class="titleTd width80px">授权时间</td>
          <td class="valueTd">
            <div class="block">
              <el-date-picker
                v-model="dataForm.authDate"
                type="date"
                clearable
                style="width: 100%"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </div>
          </td>

          <td class="titleTd width80px">授权对象</td>
          <td class="valueTd">
            <region-tree :rootId="gridId !== '' ? gridId : regionId" placeholder="请选择授权对象" v-model="dataForm.regionId" > </region-tree>
          </td>

        </tr>
      </table>
      <div class="wxts_msg_search">
        <div class="f_t">
          <el-button-group :disabled="dataListLoading">
            <el-button size="mini" @click="currentChangeHandle(1)" type="success" :loading="dataListLoading">查询</el-button>
            <el-button size="mini" v-if="isAuth('dataflow:olxareaauthmain:save')" type="primary" @click="addOrUpdateHandle()">新增
            </el-button>
            <el-button size="mini" v-if="isAuth('dataflow:olxareaauthmain:delete')" type="danger" @click="deleteHandle()"
                       :disabled="dataListSelections.length <= 0">批量删除
            </el-button>
          </el-button-group>
        </div>
      </div>
    </el-form>
    <el-table
        :data="dataList"
        border
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        style="width: 100%;">
      <el-table-column
          type="selection"
          header-align="center"
          align="center"
          width="50">
      </el-table-column>
      <el-table-column
          prop="selfAreaCode"
          header-align="center"
          align="center"
          label="授权对象">
      </el-table-column>
      <el-table-column
          prop="permissionCode"
          header-align="center"
          align="center"
          :show-overflow-tooltip="true"
          label="授权对象可查看辖区">
      </el-table-column>
      <el-table-column
          prop="authDate"
          header-align="center"
          align="center"
          label="授权时间">
      </el-table-column>
      <el-table-column
          prop="userName"
          header-align="center"
          align="center"
          label="授权人">
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop="areaCode"-->
      <!--        header-align="center"-->
      <!--        align="center"-->
      <!--        label="本区域代码">-->
      <!--      </el-table-column>-->
      <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          width="150"
          label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" v-if="isAuth('dataflow:olxareaauthmain:update') && dataForm.name == scope.row.userName"
                     @click="addOrUpdateHandle(scope.row.id)">修改
          </el-button>
          <el-button type="text" size="small" class="btn" v-if="scope.row.useType == 1 && dataForm.name == scope.row.userName" @click="useType(scope.row.id)">禁用
          </el-button>
          <el-button type="text" size="small" v-if="scope.row.useType == 2 && dataForm.name == scope.row.userName" @click="useType(scope.row.id)">启用
          </el-button>
          <el-button type="text" size="small" class="del-button" v-if="isAuth('dataflow:olxareaauthmain:delete') && dataForm.name == scope.row.userName"
                     @click="deleteHandle(scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalCount"
        layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>
<script>

import AddOrUpdate from './mainadd-or-update'
import {getList, deleteData, updateType} from '@/api/dataflow/olxareaauthmain.js'

export default {
  data() {
    return {
      dataForm: {
        userName: '',
        authDate: '',
        useType: '',
        name: '',
        regionId: '',

      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    }
  },
  components: {
    AddOrUpdate
  },
  activated() {
    //this.dataForm.key =  this.$route.query.key;
    let pageIndex = this.$route.query.pageIndex;
    if (pageIndex) {
      this.pageIndex = parseInt(pageIndex);
    }

    let pageSize = this.$route.query.pageSize;
    if (pageSize) {
      this.pageSize = parseInt(pageSize);
    }
    this.getDataList();
  },

  computed: {
    regionId: {
      get() {
        const newregionId = this.$store.state.user.regionId;
        if (newregionId !== undefined) {
          // console.log("regionId=====>" + newregionId)
          return Number(newregionId);
        } else {
          return ''
        }
      }
    },
    gridId: {
      get() {
        const newgridId = this.$store.state.user.gridId;
        if (newgridId !== undefined) {
          // console.log("gridId=====>" + newgridId)
          return Number(newgridId);
        } else {
          return ''
        }
      }
    },
    regionCode: {
      get() {
        return this.$store.state.user.regionCode;
      }
    },
    gridCode: {
      get() {
        return this.$store.state.user.gridCode;
      }
    },
  },

  methods: {
    refresh() {
      // this.$router.push({name:'common_olxareaauthmain',query: {key:this.dataForm.key,pageIndex:this.pageIndex,pageSize:this.pageSize}});
    },

    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.dataForm.name = this.$cookie.get("Username");

      const params_ = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'regionId': this.$store.state.user.regionId !== undefined ? this.$store.state.user.regionId : '0',
        //'userName': this.dataForm.userName,
        'authDate': this.dataForm.authDate,
        //'useType': this.dataForm.useType
        'areaCode': this.dataForm.regionId,

      }
      getList(params_).then(({data}) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list
          this.totalCount = data.data.totalCount
        } else {
          this.dataList = []
          this.totalCount = 0
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error("查询失败")
      }).finally(() => {
        this.dataListLoading = false
      })
    },

    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
      this.refresh()
    },

    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
      this.refresh()
    },

    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },

    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },

    //修改使用状态
    useType(id) {
      updateType(id).then(({data}) => {
        this.getDataList()
      }).catch((err) => {
        console.error("修改失败")
      }).finally(() => {
        this.dataListLoading = false;
      })
    },

    // 删除
    deleteHandle(row) {
      const ids = row ? [row.id] : this.dataListSelections.map(item => {
        return item.id
      })
      //const names = row ? [row.name] : this.dataListSelections.map(item => {
      //  return item.name
      //})

      this.$confirm(`确定要进行[${row ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListLoading = true
        deleteData(ids).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000
            })
            this.getDataList()
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error("删除失败")
        }).finally(() => {
          this.dataListLoading = false
        })
      }).catch(_ => {
      })
    }
  }
}
</script>
